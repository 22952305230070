
<template>
  <v-card>
    <v-card-title>
      Cashout
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
      <v-alert :value="showExportMessage" 
        colored-border
        dismissible
        color="primary lighten-1"
        border="top"
        icon="mdi-check"
        transition="scale-transition"
        elevation="2"
        prominent
      >
        🤾🏻‍♀️ 🤸🏻‍♂️ 🏌🏻‍♀️ 🤾🏻‍♂️ Your export is currently processing. Once the export is completed 🏁, you will receive a notification via Slack under <code>#admindashboard</code> channel 📢.
      </v-alert>

      <v-row>
        <v-col cols="4">
          <v-select
            label="Credited"
            :items="[true, false]"
            v-model="filters.credited"
          />
        </v-col>

        <v-col cols="4">
          <v-select
            label="Countries"
            :items="countries"
            v-model="filters.countries"
            v-on:change="enableButton"
          />
        </v-col>

        <v-col cols="4">
          <v-select
            label="User Status"
            :items="userStatus"
            v-model="filters.userStatus"
            item-text="label"
            item-value="value"
          />
        </v-col>
      </v-row>

      <v-btn color="primary" @click="loadCashout">Load</v-btn>
      <v-btn :disabled="btnDisable" color="primary" class="ml-3" @click="exportCashout">Export</v-btn>
      <v-btn color="primary" class="ml-3" @click="$router.push({ name: 'MobileCashoutBulkUpdate' } )">Bulk Update</v-btn>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="cashouts" 
      class="elevation-1"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="$router.push({ path: `/mobile/reward/${item.rewardId}/edit`, params: item })">visibility</v-icon>
        <v-icon small class="mr-2" @click="$router.push({ path: `/mobile/cashout/${item._id}/edit`, params: item })">edit</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "ID Number", value: "idNumber", sortable: true },
        { text: "Country", value: "country", sortable: true },
        { text: "Currency", value: "currency", sortable: true },
        { text: "Amount", value: "amountInCurrency", sortable: false },
        { text: "Credited", value: "credited", sortable: true },
        { text: "Created At", value: "createdAt", sortable: false },
        { text: "Action", value: "actions", sortable: false },
      ],
      showExportMessage: false,
      cashouts: [],
      filters: {
        credited: false,
        // countries: []
      },
      countries: [],
      btnDisable: true,
      userStatus: [
        {label: "Active", value: "A"},
        {label: "Inactive", value: "I"},
        {label: "Disabled", value: "D" },
      ]
    };
  },
  mounted() {
    this.fetchCountries();
    this.loadCashout();
  },
  methods: {
    loadCashout() {
      this.$setLoader();
      this.showExportMessage = false
      service.fetchCashout(this.filters).then(r => {
        this.cashouts = r.data;
        this.$disableLoader();
      });
    },
    fetchCountries() {
      service.fetchCashout({ fetchCountries: true }).then(r => {
        this.countries = r.data
        // this.filters.countries = r.data;
        this.$disableLoader();
      });
    },
    exportCashout() {
      this.showExportMessage = true
      service.exportRewardCashoutBank(this.filters).then(r => {
        this.cashouts = r.data;
        this.$disableLoader();
      });
    },
    enableButton() {
      this.btnDisable = false
    }
  }
};
</script>
